import React from 'react';
import { Formik, Field, Form, FormikHelpers, FormikProps } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { API } from '../../../config/configuration';
import { useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

interface FormValues {
  email: string;
  password: string;
  confirmPassword: string;
}

interface ParamTypes {
  id: string;
}

export const ForgetPasswordForm: React.FC = () => {
  let { id } = useParams<ParamTypes>();
  const searchParams = new URLSearchParams(useLocation().search)
  const resetToken = searchParams.get('token');

  return (
    <>
      <Formik
        initialValues={{
          email: '',
          password: '',
          confirmPassword: '',
        }}
        onSubmit={(
          values: FormValues,
          { setSubmitting, resetForm }: FormikHelpers<FormValues>
        ) => {
          toast('กำลังดำเนินการ...', {
            position: 'bottom-center',
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          axios
            .put(API.url + 'auth/reset_password/' + id, {
              email: values.email,
              password: values.password,
              confirmPassword: values.confirmPassword,
            }, {
              params: {
                token: resetToken,
              }
            })
            .then((response) => {
              toast.dismiss();

              toast.success('การกู้รหัสผ่านสำเร็จ!', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });

              resetForm();
            })
            .catch((error) => {
              toast.dismiss();

              toast.error(`เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง! ${error.response.data.message}`, {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            });
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email('รูปแบบของอีเมลไม่ถูกต้อง')
            .required('กรุณาป้อนอีเมล'),
          password: Yup.string()
            .matches(
              /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,32}$/,
              'รหัสผ่านไม่อยู่ในรูปแบบที่กำหนด'
            )
            .required('กรุณาป้อนรหัสผ่าน'),
          confirmPassword: Yup.string()
            .required('กรุณาป้อนยืนยันรหัสผ่าน')
            .when('password', {
              is: (val: string) => (val && val.length > 0 ? true : false),
              then: Yup.string().oneOf(
                [Yup.ref('password')],
                'รหัสผ่านไม่ตรงกัน'
              ),
            }),
        })}
      >
        {(props: FormikProps<FormValues>) => {
          const { touched, errors, isSubmitting } = props;
          return (
            <Form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="email"
                >
                  อีเมล
                </label>
                <Field
                  type="email"
                  id="email"
                  name="email"
                  placeholder="ป้อนอีเมล..."
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                {touched.email && errors.email && (
                  <div className="text-red-500">{errors.email}</div>
                )}
              </div>

              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="password"
                >
                  รหัสผ่าน
                </label>
                <Field
                  id="password"
                  name="password"
                  placeholder="ป้อนรหัสผ่าน..."
                  type="password"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                {touched.password && errors.password && (
                  <div className="text-red-500">{errors.password}</div>
                )}
              </div>

              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="confirmPassword"
                >
                  ยืนยันรหัสผ่าน
                </label>
                <Field
                  id="confirmPassword"
                  name="confirmPassword"
                  placeholder="ป้อนยืนยันรหัสผ่าน..."
                  type="password"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />

                {touched.confirmPassword && errors.confirmPassword && (
                  <div className="text-red-500">{errors.confirmPassword}</div>
                )}
              </div>

              <div className="flex items-center justify-center">
                <button
                  type="submit"
                  className="bg-blue-500 hover:bg-blue-700 text-white w-full font-bold py-2 px-2 rounded focus:outline-none focus:shadow-outline"
                  disabled={isSubmitting}
                >
                  ยืนยัน
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
