import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

import './App.css';
import { Home, NotFound, Register, ResetPassword, Confirm } from './pages';

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/register" component={Register} />
        <Route path="/reset_password/:id" component={ResetPassword} />
        <Route path="/auth/confirm" component={Confirm} />
        <Route path="*" component={NotFound} />
      </Switch>
    </Router>
  );
}

export default App;
