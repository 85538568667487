import React from 'react';

export function NotFound() {
  return (
    <div className="flex h-screen">
      <div className="m-auto text-center">
        <h3 className="font-bold">404</h3>
        <p>Not Found</p>
      </div>
    </div>
  );
}
