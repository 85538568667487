import React from 'react';
import { Formik, Field, Form, FormikHelpers, FormikProps } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { API } from '../../../config/configuration';
import { toast } from 'react-toastify';

interface FormValues {
  email: string;
  password: string;
  confirmPassword: string;
  title: string;
  firstName: string;
  lastName: string;
  gender: string;
  personalId: string;
  degreeProgramId: string;
  role: string;
}

export default function RegistrationForm() {
  return (
    <>
      <Formik
        initialValues={{
          email: '',
          password: '',
          confirmPassword: '',
          title: '',
          firstName: '',
          lastName: '',
          gender: '',
          personalId: '',
          degreeProgramId: '',
          role: '',
        }}
        onSubmit={(
          values: FormValues,
          { setSubmitting, resetForm }: FormikHelpers<FormValues>
        ) => {
          toast('กำลังดำเนินการ...', {
            position: 'bottom-center',
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          axios
            .post(API.url + 'auth/register', {
              email: values.email,
              password: values.password,
              confirmPassword: values.confirmPassword,
              title: values.title || 'นาย',
              firstName: values.firstName,
              lastName: values.lastName,
              gender: values.gender || 'ชาย',
              personalId: values.personalId,
              degreeProgramId:
                values.degreeProgramId || '602e0806c3cb5a0013634c7f',
              role: 'student',
            })
            .then((response) => {
              toast.dismiss();

              toast.success('การลงทะเบียนสำเร็จ!', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });

              resetForm();
            })
            .catch((error) => {
              toast.dismiss();

              toast.error(
                `เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง! ${error.response.data.message}`,
                {
                  position: 'bottom-center',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                }
              );
            });
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email('รูปแบบของอีเมลไม่ถูกต้อง')
            .required('กรุณาป้อนอีเมล'),
          password: Yup.string()
            .matches(
              /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,32}$/,
              'รหัสผ่านไม่อยู่ในรูปแบบที่กำหนด'
            )
            .required('กรุณาป้อนรหัสผ่าน'),
          confirmPassword: Yup.string()
            .required('กรุณาป้อนยืนยันรหัสผ่าน')
            .when('password', {
              is: (val: string) => (val && val.length > 0 ? true : false),
              then: Yup.string().oneOf(
                [Yup.ref('password')],
                'รหัสผ่านไม่ตรงกัน'
              ),
            }),
        })}
      >
        {(props: FormikProps<FormValues>) => {
          const { touched, errors, isSubmitting } = props;
          return (
            <Form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="email"
                >
                  อีเมล
                </label>
                <Field
                  type="email"
                  id="email"
                  name="email"
                  placeholder="ป้อนอีเมล..."
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                {touched.email && errors.email && (
                  <div className="text-red-500">{errors.email}</div>
                )}
              </div>

              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="password"
                >
                  รหัสผ่าน
                </label>
                <Field
                  id="password"
                  name="password"
                  placeholder="ป้อนรหัสผ่าน..."
                  type="password"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                {touched.password && errors.password && (
                  <div className="text-red-500">{errors.password}</div>
                )}
              </div>

              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="confirmPassword"
                >
                  ยืนยันรหัสผ่าน
                </label>
                <Field
                  id="confirmPassword"
                  name="confirmPassword"
                  placeholder="ป้อนยืนยันรหัสผ่าน..."
                  type="password"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />

                {touched.confirmPassword && errors.confirmPassword && (
                  <div className="text-red-500">{errors.confirmPassword}</div>
                )}
              </div>
              {/* begin: title */}
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="title"
                >
                  คำนำหน้าชื่อ
                </label>
                <Field id="title" name="title" as="select">
                  <option value="นาย">นาย</option>
                  <option value="นาง">นาง</option>
                  <option value="นางสาว">นางสาว</option>
                </Field>
                {touched.title && errors.title && (
                  <div className="text-red-500">{errors.title}</div>
                )}
              </div>
              {/* end: title */}
              {/* begin: First name */}
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="firstName"
                >
                  ชื่อ (ภาษาไทย)
                </label>
                <Field
                  type="firstName"
                  id="firstName"
                  name="firstName"
                  placeholder="ป้อนชื่อจริง..."
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                {touched.firstName && errors.firstName && (
                  <div className="text-red-500">{errors.firstName}</div>
                )}
              </div>
              {/* end: First name */}
              {/* begin: Last name */}
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="lastName"
                >
                  นามสกุล (ภาษาไทย)
                </label>
                <Field
                  type="lastName"
                  id="lastName"
                  name="lastName"
                  placeholder="ป้อนนามสกุล..."
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                {touched.lastName && errors.lastName && (
                  <div className="text-red-500">{errors.lastName}</div>
                )}
              </div>
              {/* end: Last name */}
              {/* begin: gender */}
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="gender"
                >
                  เพศ
                </label>
                <Field id="gender" name="gender" as="select">
                  <option value="ชาย">ชาย</option>
                  <option value="หญิง">หญิง</option>
                  <option value="ไม่ระบุ">ไม่ระบุ</option>
                </Field>
                {touched.gender && errors.gender && (
                  <div className="text-red-500">{errors.gender}</div>
                )}

                {/* {formik.touched.gender && formik.errors.gender ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.gender}</div>
            </div>
          ) : null} */}
              </div>
              {/* end: gender */}
              {/* begin: personalId */}
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="personalId"
                >
                  รหัสนักศึกษา
                </label>
                <Field
                  type="personalId"
                  id="personalId"
                  name="personalId"
                  placeholder="ป้อนรหัสนักศึกษา..."
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                {touched.personalId && errors.personalId && (
                  <div className="text-red-500">{errors.personalId}</div>
                )}
              </div>
              {/* end: personalId */}
              {/* Degree Program */}
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="degreeProgramId"
                >
                  สาขาวิชา
                </label>
                <Field id="degreeProgramId" name="degreeProgramId" as="select">
                  <option value="602e0806c3cb5a0013634c7f">
                    เทคโนโลยีเครื่องกล
                  </option>
                  <option value="602e0806c3cb5a0013634c80">
                    เทคโนโลยีโยธา
                  </option>
                  <option value="602e0806c3cb5a0013634c81">
                    เทคโนโลยีไฟฟ้า
                  </option>
                  <option value="602e0806c3cb5a0013634c82">
                    เทคโนโลยีอิเล็กทรอนิกส์
                  </option>
                  <option value="602e0806c3cb5a0013634c83">
                    เทคโนโลยีอุตสาหการ
                  </option>
                  <option value="602e0806c3cb5a0013634c84">
                    เทคโนโลยีการจัดการ
                  </option>
                </Field>
                {touched.degreeProgramId && errors.degreeProgramId && (
                  <div className="text-red-500">{errors.degreeProgramId}</div>
                )}
              </div>

              <div className="flex items-center justify-center">
                <button
                  type="submit"
                  className="bg-blue-500 hover:bg-blue-700 text-white w-full font-bold py-2 px-2 rounded focus:outline-none focus:shadow-outline"
                  disabled={isSubmitting}
                >
                  ยืนยัน
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}
