import React from 'react';
import RegistrationForm from './RegistrationForm';

export function Registration() {
  return (
    <div className="registrationForm">
      <div className="container mx-auto">
        <div className="flex  justify-center h-screen p-2">
          <div className="w-full max-w-xs">
            <div className="registrationForm_title text-center mb-4">
              <p className="text-gray-500">FIET Note</p>
              <h1 className="text-xl font-bold">การลงทะเบียน</h1>
            </div>

            {/* begin::Formik */}
            <RegistrationForm />
            {/* end::Formik */}
            <p className="text-center text-gray-500 text-xs">
              &copy;2020 TiDo Technology Co., Ltd. <br />
              All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
