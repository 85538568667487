import React from 'react';
import { ForgetPasswordForm } from './ForgetPasswordForm';

export function ForgetPassword() {
  return (
    <div className="forgetPasswordForm">
      <div className="container mx-auto">
        <div className="flex items-center justify-center h-screen p-2">
          <div className="w-full max-w-xs">
            <div className="forgetPasswordForm_title text-center mb-4">
              <p className="text-gray-500">FIET Note</p>
              <h1 className="text-xl font-bold">การกู้รหัสผ่าน</h1>
            </div>

            {/* begin::Formik */}
            <ForgetPasswordForm />
            {/* end::Formik */}
            <p className="text-center text-gray-500 text-xs">
              &copy;2020 TiDo Technology Co., Ltd. <br />
              All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
