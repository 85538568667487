import React from 'react';

export function Home() {
  return (
    <div className="flex h-screen">
      <div className="m-auto text-center">
        <h3 className="font-bold">FIET Note</h3>
        <p>Account</p>
      </div>
    </div>
  );
}
