import React from "react";
import { ToastContainer } from "react-toastify";
import { ForgetPassword } from "../../components/Auth";

export function ResetPassword() {
  return (
    <>
      <ForgetPassword />
      <ToastContainer />
    </>
  );
}
