import React from 'react';
import { ToastContainer } from 'react-toastify';
import { Registration } from '../../components/Auth';

export function Register() {
  return (
    <>
      <Registration />
      <ToastContainer />
    </>
  );
}
