import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { API } from "../../config/configuration";

export function Confirm() {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string>();

  const searchParams = new URLSearchParams(useLocation().search);
  const uid = searchParams.get("uid");
  const token = searchParams.get("token");

  useEffect(() => {
    const params = { uid, token };

    axios
      .post(API.url + "auth/confirm", null, {
        params,
      })
      .catch((error) => setError(error.response.data.message));

    setIsLoading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Loading = () => <p>⏳ Loading...</p>;
  const Success = () => {
    return (
      <>
        <h1 className="font-bold">FIET Note</h1>
        <p>Email Confirmation Successful 🎉</p>
      </>
    );
  };
  const ErrorResponse = (error: string) => (
    <>
      <h1 className="font-bold">Error ❗️</h1>
      <p>{error}</p>
    </>
  );

  return (
    <div className="flex h-screen">
      <div className="m-auto text-center">
        {isLoading ? <Loading /> : error ? ErrorResponse(error) : <Success />}
      </div>
    </div>
  );
}
